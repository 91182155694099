<template>
    <div>
        <div class="helper-box">
            <p>If you do not want one of the below items to appear in your footer, please leave the field blank.</p>
        </div>
        <div class="form-row mt-8">
            <div class="form-col">
                <div>
                    <label class="mb-1">logo</label>
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                        :libraryFilters="['marketing', 'cms']"
                        :max-nb-of-files="1"
                        :max-upload-size="20"
                        :cropper-options="{aspectRatio: 1, cropBoxResizable: true, zoomOnWheel: false}"
                        :noCropper="true"
                        @uploaded-to-s3="createWebsiteImage"
                        v-model="footer.logo"
                    ></file-manager>
                </div>
                <div class="mt-8">
                    <label for="instagramLink">logo url</label>
                    <text-input
                      id="logoLink"
                      v-model="footer.logoLink"
                    />
                    <span class="form-error" v-if="hasError('footer.logoLink')">{{ showError('footer.logoLink') }}</span>
                </div>
            </div>
            <div class="form-col">
                <div>
                    <label for="facebookLink">facebook url</label>
                    <text-input
                      id="facebookLink"
                      v-model="footer.facebookLink"
                    />
                    <span class="form-error" v-if="hasError('footer.facebookLink')">{{ showError('footer.facebookLink') }}</span>
                </div>

                <div class="mt-8">
                    <label for="twitterLink">twitter url</label>
                    <text-input
                      id="twitterLink"
                      v-model="footer.twitterLink"
                    />
                    <span class="form-error" v-if="hasError('footer.twitterLink')">{{ showError('footer.twitterLink') }}</span>
                </div>

                <div class="mt-8">
                    <label for="instagramLink">instagram url</label>
                    <text-input
                      id="instagramLink"
                      v-model="footer.instagramLink"
                    />
                    <span class="form-error" v-if="hasError('footer.instagramLink')">{{ showError('footer.instagramLink') }}</span>
                </div>

                <div class="mt-8">
                    <label for="instagramLink">privacy policy url</label>
                    <text-input
                      id="privacyPolicyLink"
                      v-model="footer.privacyPolicyLink"
                    />
                    <span class="form-error" v-if="hasError('footer.privacyPolicyLink')">{{ showError('footer.privacyPolicyLink') }}</span>
                </div>

                <div class="mt-8">
                    <label>Secondary Design</label>
                    <toggle-input v-model="footer.secondDesign" />
                </div>
            </div>
        </div>

       <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import FileManager from "@/components/ui/filemanager/FileManager";
import TextInput from "@/components/ui/TextInput";
import ToggleInput from "@/components/ui/ToggleInput";
import {resolveUrl} from "@/utils/String";

export default {
    mixins: [ModalNavigation, Widget],
    components: { ModalFooter, FileManager, TextInput, ToggleInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            footer: {
                facebookLink: null,
                twitterLink: null,
                instagramLink: null,
                secondDesign: false,
                privacyPolicyLink: null,
                logoLink: null,
                logo: []
            },
            isProcessing: false
        }
    },
    computed: {
        ...mapGetters({
            getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
            getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
            getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
        })
    },
    methods: {
        save() {
            if (this.validator.passes()) {
                const payload = {};
                payload.facebookLink = resolveUrl(this.footer.facebookLink);
                payload.twitterLink = resolveUrl(this.footer.twitterLink);
                payload.instagramLink = resolveUrl(this.footer.instagramLink);
                payload.secondDesign = this.footer.secondDesign;
                payload.privacyPolicyLink = resolveUrl(this.footer.privacyPolicyLink);
                payload.logoLink = resolveUrl(this.footer.logoLink);
                payload.logo = this.getThumbnailUrlByFileId(this.footer.logo[0]);
                this.isProcessing = true;
                this.$emit('save-page-widget', Object.assign({}, payload));
            }
        },
    },
    validations: {
        'footer.logoLink' : 'when_present | url | check_url_protocol',
        'footer.facebookLink': ' when_present | url | check_url_protocol',
        'footer.twitterLink': ' when_present | url | check_url_protocol',
        'footer.instagramLink': ' when_present | url | check_url_protocol',
        'footer.privacyPolicyLink': ' when_present | url | check_url_protocol',
    },
    created() {
        this.initValidator();
        this.validator.extend('check_url_protocol', (value) => {
          if(value !== '')
            return (value.includes('http://') || value.includes('https://')) ? true : false
        });
        this.validator.setCustomErrorMessages({
          'footer.logoLink.check_url_protocol' : 'url must start with http or https',
          'footer.facebookLink.check_url_protocol': 'url must start with http or https',
          'footer.twitterLink.check_url_protocol': 'url must start with http or https',
          'footer.instagramLink.check_url_protocol': 'url must start with http or https',
          'footer.privacyPolicyLink.check_url_protocol': 'url must start with http or https',
        })
        if (this.values.length) {
            Object.assign(this.footer, {
                facebookLink: this.getValue('facebookLink'),
                twitterLink: this.getValue('twitterLink'),
                instagramLink: this.getValue('instagramLink'),
                privacyPolicyLink: this.getValue('privacyPolicyLink'),
                logoLink: this.getValue('logoLink'),
                logo: this.getValue('logo') ? [ this.getFileIdByThumbnailUrl( this.getValue('logo') ) ] : [],
                secondDesign: this.getValue('secondDesign')
            });
        }
    }
}
</script>
